import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HalfHeader from "../components/halfBGImage";
import "../utils/normalize.css";
import "../utils/css/screen.css";
import { motion } from "framer-motion";
import { useIntl } from "react-intl";
import ReactPlayer from "react-player";

const ElementsPage = ({ data }, props) => {
  const siteTitle = data.site.siteMetadata.title;
  let imageCounter = 0;
  const intl = useIntl();
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={intl.formatMessage({ id: "nav5" })}
        keywords={[`pianist`, `prodigy`, `classical musician`, `piano`]}
      />

      <HalfHeader data={data} title={intl.formatMessage({ id: "gallery" })} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <hr />
          <div className="galleryContainer2">
            {data.gallery.edges.map(({ node }) => {
              imageCounter++;
              return (
                <motion.figure
                  className="imageParentFigure2"
                  whileHover={{ scale: 1.05 }}
                  transition={{ ease: "easeOut", duration: 0.4 }}
                >
                  <Img
                    key={node.id}
                    count={imageCounter}
                    fluid={node.childImageSharp.fluid}
                    className="ImageItselfLarge2"
                  />
                </motion.figure>
              );
            })}
          </div>
        </div>
      </article>
      {/* <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>{intl.formatMessage({ id: "hobbies" })}</h2>
          <hr />
          <div className="galleryContainer">
            {data.hobbies.edges.map(({ node }) => {
              imageCounter++;
              return (
                <motion.figure
                  className="imageParentFigure"
                  whileHover={{ scale: 1.05 }}
                  transition={{ ease: "easeOut", duration: 0.4 }}
                >
                  <Img
                    key={node.id}
                    count={imageCounter}
                    fluid={node.childImageSharp.fluid}
                    className="ImageItselfLarge"
                  />
                </motion.figure>
              );
            })}
            <ReactPlayer
              className="imageVideoFigure"
              url="https://youtu.be/s3kLzmc_jbc"
              playing={false}
              volume={1}
              autoplay={false}
              loop={false}
              controls={true}
              playsinline={true}
              pip={true}
              stopOnUnmount={false}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    responsive: 1,
                    fs: 0,
                  },
                },
              }}
            />
          </div>
        </div>
      </article>*/}

    </Layout>
  );
};

const indexQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        gallery: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                    id
                }
            }
        }
        hobbies: allFile(filter: { relativeDirectory: { eq: "hobbies" } }) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                    id
                }
            }
        }
        mobileImage: file(relativePath: { eq: "ilyun-burkev-desktop-gallery-2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 490) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        desktopImage: file(relativePath: { eq: "ilyun-burkev-desktop-gallery-2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
)
